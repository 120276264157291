export const MASK_CHAR = '-';
export const DEFAULT_VALUE_HHMM = '';
export const NO_DATA_PLACEHOLDER = '';
export const FORMAT_HHMM = '##:##';
export const MILLISECONDS_PER_HOUR = 1000 * 60 * 60;
export const BREAK_TIME_NORMAL_HOUR = 1;
export const BREAK_TIME_NO_TIME_HOUR = 0;
export const HOLIDAYS = [
  // 2024
  { name: '元日', date: new Date('2024-01-01') },
  { name: '成人の日', date: new Date('2024-01-08') },
  { name: '建国記念日の振替休日', date: new Date('2024-02-12') },
  { name: '天皇誕生日', date: new Date('2024-02-23') },
  { name: '有給推奨日', date: new Date('2024-02-26') },
  { name: '春分の日', date: new Date('2024-03-20') },
  { name: '昭和の日', date: new Date('2024-04-29') },
  { name: '憲法記念日', date: new Date('2024-05-03') },
  { name: 'みどりの日', date: new Date('2024-05-04') },
  { name: 'こどもの日の振替休日', date: new Date('2024-05-06') },
  { name: '海の日', date: new Date('2024-07-15') },
  { name: '山の日の振替休日', date: new Date('2024-08-12') },
  { name: '敬老の日', date: new Date('2024-09-16') },
  { name: '春分の日の振替休日', date: new Date('2024-09-23') },
  { name: 'スポーツの日', date: new Date('2024-10-14') },
  { name: '文化の日の振替休日', date: new Date('2024-11-04') },
  { name: '勤労感謝の日', date: new Date('2024-11-23') },
  { name: '年末休み', date: new Date('2024-12-28') },
  { name: '年末休み', date: new Date('2024-12-29') },
  { name: '年末休み', date: new Date('2024-12-30') },
  { name: '年末休み', date: new Date('2024-12-31') },
  // 2025
  { name: '元日', date: new Date('2025-01-01') },
  { name: '年始休み', date: new Date('2025-01-02') },
  { name: '年始休み', date: new Date('2025-01-03') },
  { name: '成人の日', date: new Date('2025-01-13') },
  { name: '建国記念日', date: new Date('2025-02-11') },
  { name: '天皇誕生日', date: new Date('2025-02-23') },
  { name: '天皇誕生日の振替休日', date: new Date('2025-02-24') },
  { name: '春分の日', date: new Date('2025-03-20') },
  { name: '昭和の日', date: new Date('2025-04-29') },
  { name: '憲法記念日', date: new Date('2025-05-03') },
  { name: 'みどりの日', date: new Date('2025-05-04') },
  { name: 'こどもの日', date: new Date('2025-05-05') },
  { name: 'こどもの日の振替休日', date: new Date('2025-05-06') },
  { name: '海の日', date: new Date('2025-07-21') },
  { name: '山の日', date: new Date('2025-08-11') },
  { name: '敬老の日', date: new Date('2025-09-15') },
  { name: '秋分の日', date: new Date('2025-09-23') },
  { name: 'スポーツの日', date: new Date('2025-10-13') },
  { name: '文化の日', date: new Date('2025-11-03') },
  { name: '勤労感謝の日', date: new Date('2025-11-23') },
  { name: '勤労感謝の日の振替休日', date: new Date('2025-11-24') },
];

export enum LEAVE {
  NULL = 'NULL',
  PAID_LEAVE = 'PAID_LEAVE',
  MORNING_PAID_LEAVE = 'MORNING_PAID_LEAVE',
  AFTERNOON_PAID_LEAVE = 'AFTERNOON_PAID_LEAVE',
  BEREAVEMENT_LEAVE = 'BEREAVEMENT_LEAVE',
  COMPENSATORY_LEAVE = 'COMPENSATORY_LEAVE',
  ABSENCE = 'ABSENCE',
  SPECIAL_LEAVE = 'SPECIAL_LEAVE',
}

export const LEAVE_DISPLAY_VALUES: { [key: string]: string } = {
  [LEAVE.NULL]: `${NO_DATA_PLACEHOLDER}`,
  [LEAVE.PAID_LEAVE]: '有休',
  [LEAVE.MORNING_PAID_LEAVE]: '午前有休',
  [LEAVE.AFTERNOON_PAID_LEAVE]: '午後有休',
  [LEAVE.BEREAVEMENT_LEAVE]: '慶弔休',
  [LEAVE.COMPENSATORY_LEAVE]: '代休',
  [LEAVE.ABSENCE]: '欠勤',
  [LEAVE.SPECIAL_LEAVE]: '特休',
};

export const LEAVE_TOOLTIPS: { [key: string]: string } = {
  [LEAVE.NULL]: '休暇を選択してください',
  [LEAVE.PAID_LEAVE]: '有休',
  [LEAVE.MORNING_PAID_LEAVE]: '午前有休',
  [LEAVE.AFTERNOON_PAID_LEAVE]: '午後有休',
  [LEAVE.BEREAVEMENT_LEAVE]: '家族の不幸や冠婚葬祭に基づく休暇',
  [LEAVE.COMPENSATORY_LEAVE]: '土日労働した時間を翌日以降に振り替える休暇',
  [LEAVE.ABSENCE]: '無断欠勤や病欠などによる、出勤扱いとならない休暇',
  [LEAVE.SPECIAL_LEAVE]: '会社の規定に基づいた特別な理由で取得する休暇',
};
