import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './graphql/apolloClient';
import ReactGA from 'react-ga';

// Importing Common
import Loader from './common/Loader';

// Importing components
import PageTitle from './components/PageTitle';

// Importing pages
import SignInPage from './pages/Authentication/SignInPage';
import SignUpPage from './pages/Authentication/SignUpPage';
import SetNewPassword from './pages/Authentication/SetNewPasswordPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import TimecardPage from './pages/Dashboard/Timecard/TimecardPage';
import AttendancePage from './pages/Dashboard/Attendance/AttendancePage';
import ManageAttendancePage from './pages/Dashboard/Manage/ManageAttendancePage';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <ApolloProvider client={client}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <PageTitle title="ログイン | ark" />
                  <SignInPage />
                </>
              }
            />
            <Route
              path="/auth/signup"
              element={
                <>
                  <PageTitle title="サインアップ | ark" />
                  <SignUpPage />
                </>
              }
            />
            <Route
              path="/setNewPassword"
              element={
                <>
                  <PageTitle title="新しいパスワード | ark" />
                  <SetNewPassword />
                </>
              }
            />
            <Route
              path="/dashboard"
              element={
                <>
                  <PageTitle title="ダッシュボード | ark" />
                  <DashboardPage />
                </>
              }
            />
            <Route
              path="/timecard"
              element={
                <>
                  <PageTitle title="タイムカード | ark" />
                  <TimecardPage />
                </>
              }
            />
            <Route
              path="/attendance"
              element={
                <>
                  <PageTitle title="勤怠表 | ark" />
                  <AttendancePage />
                </>
              }
            />
            <Route
              path="/manage/attendance"
              element={
                <>
                  <ManageAttendancePage />
                </>
              }
            />
          </Routes>
        </>
      )}
    </ApolloProvider>
  );
}

export default App;
