import React from 'react';

interface LoadingIndicatorProps {
  size?: 'small' | 'medium' | 'large';
}

const spinnerSizes: Record<'small' | 'medium' | 'large', number> = {
  small: 30,
  medium: 60,
  large: 100,
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ size = 'medium' }) => {
  const spinnerSize = spinnerSizes[size];
  const spinnerStyle: React.CSSProperties = {
    width: spinnerSize,
    height: spinnerSize,
    border: `${spinnerSize / 10}px solid #f3f3f3`,
    borderTop: `${spinnerSize / 10}px solid #3498db`,
    borderRadius: '50%',
    animation: 'spin 2s linear infinite',
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={spinnerStyle} />
      {/* 以下は必要に応じてグローバルCSSに移動してください */}
      <style>{`
				@keyframes spin {
					0% { transform: rotate(0deg); }
					100% { transform: rotate(360deg); }
				}
			`}</style>
    </div>
  );
};

export default LoadingIndicator;
