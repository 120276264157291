import React, { useEffect, useLayoutEffect, useState } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { GET_DAILY_RECORD_BY_MONTH, CREATE_REPORT } from '../../../graphql/queries';
import client from '../../../graphql/apolloClient';
import DefaultLayout from '../../../layout/DefaultLayout';
import { AttendanceRecord } from '../../../common/Interface/AttendanceRecord';
import { User, getUsers } from '../../../config/users';
import { useNavigate, useLocation } from 'react-router-dom';
import AttendanceMonthTable from '../Attendance/AttendanceMonthTable';
import { useMutation } from '@apollo/client';
import { HiDownload } from 'react-icons/hi';

const ManageAttendancePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // URLクエリから初期値を取得
  const searchParams = new URLSearchParams(location.search);
  const initialUserId = searchParams.get('userId') || '';
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>(initialUserId);
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState<string>(
    (new Date().getMonth() + 1).toString(),
  );
  const [attendanceData, setAttendanceData] = useState<AttendanceRecord[]>([]);
  const [totalShiftTime, setTotalShiftTime] = useState<string>('');

  const [createReport] = useMutation(CREATE_REPORT);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const isAdmin = attributes['custom:role'] === 'manager';
        console.log(isAdmin);

        if (!isAdmin) {
          navigate('/timecard');
          return;
        }
      } catch (error) {
        console.error('認証エラー:', error);
        navigate('/');
      }
    };
    fetchUser();
  }, [navigate]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsers(getUsers());
      } catch (error) {
        console.error('ユーザー一覧の取得に失敗:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedUserId && selectedMonth && selectedYear) {
        const year = parseInt(selectedYear);
        const month = parseInt(selectedMonth);
        // 指定された年と月を使ってDateオブジェクトを構築し、月初めの午前0時（UTC+09:00）に設定する
        const firstDayOfMonth = new Date(
          `${year}-${month.toString().padStart(2, '0')}-01T00:00:00+09:00`,
        ); // データベースに保存されているUTCフォーマットへの時刻変更
        try {
          const { data } = await client.query({
            query: GET_DAILY_RECORD_BY_MONTH,
            variables: { params: { UserID: selectedUserId, Date: firstDayOfMonth.toISOString() } },
            fetchPolicy: 'network-only', // キャッシュを使用せず、常に新しいデータを取得
          });
          if (data) {
            // ダミーデータとマージするためにデータベースからデータを取得。
            const attendanceDataOfMonth = mergeDummyWithActualData(
              data.getDailyRecordsByMonth || [],
              firstDayOfMonth,
              selectedUserId,
            );
            console.log(attendanceDataOfMonth);
            setAttendanceData(attendanceDataOfMonth);
          } else {
            console.log('No data Present');
            setAttendanceData([]);
          }
        } catch (error) {
          // その日に初めてページにアクセスされたときのみ発生する
          console.log('Error fetching data:', error);
          setAttendanceData([]);
        } finally {
          console.log('Data fetched');
        }
      }
    };
    // This component is mounted, when the intial render is ran once
    fetchData();
  }, [selectedUserId, selectedMonth, selectedYear]);

  // selectedUserIdが変わったらURLクエリを更新
  useEffect(() => {
    const search = new URLSearchParams();
    if (selectedUserId) {
      search.set('userId', selectedUserId);
    }
    navigate(`${location.pathname}?${search.toString()}`, { replace: true });
  }, [selectedUserId]);

  // ユーザー一覧取得後、またはselectedUserId変更時にページタイトルを更新
  useLayoutEffect(() => {
    if (selectedUserId) {
      const selectedUser = users.find((user) => user.userId === selectedUserId);
      document.title = selectedUser ? `勤怠管理 - ${selectedUser.username}` : '勤怠管理';
    } else {
      document.title = '勤怠管理';
    }
  }, [selectedUserId, users]);

  const mergeDummyWithActualData = (
    fetchRecords: AttendanceRecord[],
    firstDayOfMonth: Date,
    fetchingUserID: string,
  ): AttendanceRecord[] => {
    const fillRecords: AttendanceRecord[] = [];

    // Create a new Date object for the last Element of the Month
    // 例: Sun Mar 31 2024 00:00:00 GMT+0900 (Japan Standard Time)
    const lastElementOfMonth = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth() + 1,
      0,
    );

    // 月のヌルデータを準備する
    let dummyDay = firstDayOfMonth;
    while (dummyDay <= lastElementOfMonth) {
      // UTCフォーマット
      const newRecord: AttendanceRecord = {
        __typename: 'DailyRecord',
        UserID: fetchingUserID,
        Date: dummyDay,
        Leave: null,
        StartTime: null,
        EndTime: null,
        Note: null,
      };
      fillRecords.push(newRecord);

      // next day
      dummyDay = new Date(dummyDay);
      dummyDay.setDate(dummyDay.getDate() + 1);
    }

    fillRecords.forEach((fillRecord, index) => {
      // Check if the date of the current fillRecord is present in records
      const matchingfetchRecord = fetchRecords.find(
        (fetchRecord) => new Date(fetchRecord.Date).getTime() === fillRecord.Date.getTime(),
      );

      if (matchingfetchRecord) {
        // Nullをデーターで変える
        fillRecords[index] = {
          ...matchingfetchRecord,
          Date: new Date(matchingfetchRecord.Date),
          StartTime: matchingfetchRecord.StartTime ? new Date(matchingfetchRecord.StartTime) : null,
          EndTime: matchingfetchRecord.EndTime ? new Date(matchingfetchRecord.EndTime) : null,
        };
      } else {
        // ヌルのままにしておく
        //console.log('Record not matched.');
      }
    });
    return fillRecords;
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  const handleDownload = async () => {
    if (!selectedUserId || !selectedMonth || !selectedYear) {
      return;
    }

    setIsDownloading(true);
    try {
      const year = parseInt(selectedYear);
      const month = parseInt(selectedMonth);
      const firstDayOfMonth = new Date(
        `${year}-${month.toString().padStart(2, '0')}-01T00:00:00+09:00`,
      );

      const { data, errors } = await createReport({
        variables: {
          params: {
            UserID: selectedUserId,
            Date: firstDayOfMonth.toISOString(),
          },
        },
      });

      // Lambda初期化関連のエラー処理
      if (
        errors?.some(
          (error) =>
            error.extensions?.errorType === 'Lambda:ResourceNotReadyException' ||
            error.extensions?.errorType === 'Lambda:IllegalArgument' ||
            error.message?.includes('Lambda is initializing'),
        )
      ) {
        alert('Lambda起動中です。10秒ほど待ってから再度お試しください。');
        return;
      }

      if (data?.createReport?.PresignedURL) {
        window.open(data.createReport.PresignedURL, '_blank');
      }
    } catch (error) {
      console.error('レポート作成エラー:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadAll = async () => {
    if (!selectedYear || !selectedMonth) return;
    setIsDownloadingAll(true);
    const year = parseInt(selectedYear);
    const month = parseInt(selectedMonth);
    const firstDayOfMonth = new Date(
      `${year}-${month.toString().padStart(2, '0')}-01T00:00:00+09:00`,
    );
    // 各ユーザに対してレポート作成処理を実施
    for (const user of users) {
      try {
        const { data, errors } = await createReport({
          variables: {
            params: {
              UserID: user.userId,
              Date: firstDayOfMonth.toISOString(),
            },
          },
        });

        // Lambda初期化関連のエラー処理
        if (
          errors?.some(
            (error) =>
              error.extensions?.errorType === 'Lambda:ResourceNotReadyException' ||
              error.extensions?.errorType === 'Lambda:IllegalArgument' ||
              error.message?.includes('Lambda is initializing'),
          )
        ) {
          alert(
            `${user.username} のレポート生成中にLambda初期化エラーが発生しました。10秒ほど待ってから再度お試しください。`,
          );
          continue;
        }

        if (data?.createReport?.PresignedURL) {
          window.open(data.createReport.PresignedURL, '_blank');
        }
      } catch (error) {
        console.error(`${user.username} のレポート作成エラー:`, error);
      }
    }
    setIsDownloadingAll(false);
  };

  //   if (initialLoading || dataLoading) {
  //     return (
  //       <DefaultLayout>
  //         <LoadingIndicator />
  //       </DefaultLayout>
  //     );
  //   }

  // 追加: 勤務時間計算用のヘルパー関数
  const calculateWorkingHour = (start: Date, end: Date): number => {
    return (end.getTime() - start.getTime()) / (1000 * 60 * 60);
  };

  const timeDisplayFormat = (hours: number): string => {
    const h = Math.floor(hours);
    const m = Math.round((hours - h) * 60);
    return `${h}時間 ${m}分`;
  };

  const calculateTotalShiftTime = (): number => {
    let total = 0;
    attendanceData.forEach((record) => {
      if (record.StartTime && record.EndTime) {
        total += calculateWorkingHour(new Date(record.StartTime), new Date(record.EndTime));
      }
    });
    return total;
  };

  // 追加: attendanceData更新時に合計シフト時間を更新する
  useEffect(() => {
    if (attendanceData.length) {
      const total = calculateTotalShiftTime();
      setTotalShiftTime(timeDisplayFormat(total));
    } else {
      setTotalShiftTime('');
    }
  }, [attendanceData]);

  return (
    <DefaultLayout>
      <div className="p-6 bg-opacity-75 bg-blue-100 backdrop-blur-lg rounded-md">
        <div className="border bg-blue-900 rounded-lg p-4 mb-4">
          <div className="relative mt-2 flex items-center justify-between">
            <div className="flex gap-4">
              <div className="relative flex gap-2">
                <select
                  value={selectedUserId}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                  className="block w-48 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
                >
                  <option value="">ユーザーを選択</option>
                  {users.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.username}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  value={selectedUserId}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                  placeholder="ユーザーIDを入力"
                  className="block w-96 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
                />
              </div>

              <select
                value={selectedYear}
                onChange={handleYearChange}
                className="block w-32 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                {Array.from({ length: 5 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <option key={year} value={year.toString()}>
                      {year}年
                    </option>
                  );
                })}
              </select>

              <select
                value={selectedMonth}
                onChange={handleMonthChange}
                className="block w-32 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                {Array.from({ length: 12 }, (_, index) => {
                  const monthNumber = index + 1;
                  return (
                    <option key={monthNumber} value={monthNumber.toString()}>
                      {monthNumber}月
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex gap-4">
              <button
                onClick={handleDownload}
                disabled={!selectedUserId || isDownloading}
                className="flex items-center gap-2 px-4 py-2.5 bg-green-600 text-white rounded-md hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isDownloading ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    処理中...
                  </>
                ) : (
                  <>
                    <HiDownload className="w-5 h-5" />
                    勤怠表DL
                  </>
                )}
              </button>
              <button
                onClick={handleDownloadAll}
                disabled={isDownloadingAll || users.length === 0}
                className="flex items-center gap-2 px-4 py-2.5 bg-purple-600 text-white rounded-md hover:bg-purple-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isDownloadingAll ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    全ユーザ処理中...
                  </>
                ) : (
                  <>
                    <HiDownload className="w-5 h-5" />
                    全ユーザ勤怠表DL
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {totalShiftTime && (
          <div className="mt-4 text-right font-bold">合計シフト時間: {totalShiftTime}</div>
        )}

        {selectedUserId ? (
          attendanceData.length > 0 ? (
            <AttendanceMonthTable
              attendanceData={attendanceData}
              onValidDataChange={() => {
                /* データ更新時の処理 */
              }}
              isManagerPage={true}
            />
          ) : (
            <p className="text-center mt-4">データがありません</p>
          )
        ) : (
          <p className="text-center mt-4">ユーザーを選択してください</p>
        )}
      </div>
    </DefaultLayout>
  );
};

export default ManageAttendancePage;
