import React, { useEffect, useState } from 'react';
import { getCurrentUser, AuthUser } from 'aws-amplify/auth';
import { GET_DAILY_RECORD_BY_MONTH } from '../../../graphql/queries';
import client from '../../../graphql/apolloClient';
import SignInPage from '../../Authentication/SignInPage';
import DefaultLayout from '../../../layout/DefaultLayout';
import LoadingIndicator from '../../../components/Loading/LoadingIndicator';
import AttendanceMonthTable from './AttendanceMonthTable';
import { AttendanceRecord } from '../../../common/Interface/AttendanceRecord';
import { calculateWorkingHour, timeDisplayFormat } from '../../../utils/Utils';

const AttendancePage: React.FC = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [attendanceData, setAttendanceData] = useState<AttendanceRecord[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [totalShiftTime, setTotalShiftTime] = useState<string>('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (error) {
        console.error('Error Fetching User', error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user && selectedMonth && selectedYear) {
        const year = parseInt(selectedYear);
        const month = parseInt(selectedMonth);
        // 指定された年と月を使ってDateオブジェクトを構築し、月初めの午前0時（UTC+09:00）に設定する
        const firstDayOfMonth = new Date(
          `${year}-${month.toString().padStart(2, '0')}-01T00:00:00+09:00`,
        ); // データベースに保存されているUTCフォーマットへの時刻変更
        try {
          setDataLoading(true);
          const { data } = await client.query({
            query: GET_DAILY_RECORD_BY_MONTH,
            variables: { params: { UserID: user.userId, Date: firstDayOfMonth.toISOString() } },
            fetchPolicy: 'network-only', // キャッシュを使用せず、常に新しいデータを取得
          });
          if (data) {
            // ダミーデータとマージするためにデータベースからデータを取得。
            const attendanceDataOfMonth = mergeDummyWithActualData(
              data.getDailyRecordsByMonth || [],
              firstDayOfMonth,
              user.userId,
            );
            setAttendanceData(attendanceDataOfMonth);
          } else {
            console.log('No data Present');
            setAttendanceData([]);
          }
        } catch (error) {
          // その日に初めてページにアクセスされたときのみ発生する
          console.log('Error fetching data:', error);
          setAttendanceData([]);
        } finally {
          setDataLoading(false);
          setInitialLoading(false);
        }
      }
    };
    // This component is mounted, when the intial render is ran once
    fetchData();
  }, [user, refresh, selectedMonth, selectedYear]);

  useEffect(() => {
    // 初期ローディングが完了し、ローディングウィンドウのアクションが表示されないように値がfalseに設定される
    if (!dataLoading) {
      setInitialLoading(false);
      setRefresh(false);
    }
  }, [dataLoading]);

  useEffect(() => {
    setTotalShiftTime(timeDisplayFormat(calculateTotalShiftTime()));
  }, [attendanceData]);

  const validDataChange = () => {
    setTotalShiftTime(timeDisplayFormat(calculateTotalShiftTime()));
  };

  // attendanceData を使用した総シフト時間計算する
  const calculateTotalShiftTime = () => {
    let totalShiftTime = 0;
    attendanceData.forEach((record) => {
      if (record.StartTime && record.EndTime) {
        const workingTime = calculateWorkingHour(record.StartTime, record.EndTime);

        // 合計時間計算式, シフト数が正の場合、加算される
        if (workingTime > 0) {
          totalShiftTime += workingTime;
        }
      }
    });
    return totalShiftTime;
  };

  const mergeDummyWithActualData = (
    fetchRecords: AttendanceRecord[],
    firstDayOfMonth: Date,
    userId: string,
  ): AttendanceRecord[] => {
    const fillRecords: AttendanceRecord[] = [];

    // Create a new Date object for the last Element of the Month
    // 例: Sun Mar 31 2024 00:00:00 GMT+0900 (Japan Standard Time)
    const lastElementOfMonth = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth() + 1,
      0,
    );

    // 月のヌルデータを準備する
    let dummyDay = firstDayOfMonth;
    while (dummyDay <= lastElementOfMonth) {
      // UTCフォーマット
      const newRecord: AttendanceRecord = {
        __typename: 'DailyRecord',
        UserID: userId,
        Date: dummyDay,
        Leave: null,
        StartTime: null,
        EndTime: null,
        Note: null,
      };
      fillRecords.push(newRecord);

      // next day
      dummyDay = new Date(dummyDay);
      dummyDay.setDate(dummyDay.getDate() + 1);
    }

    fillRecords.forEach((fillRecord, index) => {
      // Check if the date of the current fillRecord is present in records
      const matchingfetchRecord = fetchRecords.find(
        (fetchRecord) => new Date(fetchRecord.Date).getTime() === fillRecord.Date.getTime(),
      );

      if (matchingfetchRecord) {
        // Nullをデーターで変える
        fillRecords[index] = {
          ...matchingfetchRecord,
          Date: new Date(matchingfetchRecord.Date),
          StartTime: matchingfetchRecord.StartTime ? new Date(matchingfetchRecord.StartTime) : null,
          EndTime: matchingfetchRecord.EndTime ? new Date(matchingfetchRecord.EndTime) : null,
        };
      } else {
        // ヌルのままにしておく
        //console.log('Record not matched.');
      }
    });
    return fillRecords;
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setSelectedMonth(event.target.value); // Update selected month when user selects a different month
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear().toString();
    const currenMonth = (new Date().getMonth() + 1).toString();
    setSelectedYear(currentYear);
    setSelectedMonth(currenMonth);
  }, []);

  if (initialLoading || dataLoading) {
    // Show loading indicator during intial loading
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  if (user !== null) {
    return (
      <DefaultLayout>
        <div className="p-6 bg-opacity-75 bg-blue-100 backdrop-blur-lg rounded-md">
          <div className="border bg-blue-900 rounded-lg p-4 mb-4">
            <div className="relative mt-2 flex gap-4">
              <select
                value={selectedYear || ''}
                onChange={handleYearChange}
                className="block w-32 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                <option value="">選択年</option>
                {Array.from({ length: 5 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <option key={year} value={year.toString()}>
                      {year}年
                    </option>
                  );
                })}
              </select>

              <select
                value={selectedMonth || ''}
                onChange={handleMonthChange}
                className="block w-32 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                <option value="">月選択</option>
                {Array.from({ length: 12 }, (_, index) => {
                  const monthNumber = index + 1;
                  return (
                    <option key={monthNumber} value={monthNumber.toString()}>
                      {monthNumber}月
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {dataLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <AttendanceMonthTable
                attendanceData={attendanceData}
                onValidDataChange={validDataChange}
              />
              <p className="mt-4 text-lg font-semibold text-black">合計時間: {totalShiftTime}</p>
            </>
          )}
        </div>
      </DefaultLayout>
    );
  } else {
    return <SignInPage />;
  }
};

export default AttendancePage;
