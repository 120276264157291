import {
  MILLISECONDS_PER_HOUR,
  BREAK_TIME_NORMAL_HOUR,
  BREAK_TIME_NO_TIME_HOUR,
  HOLIDAYS,
  LEAVE,
} from '../common/constants';

/**
 * 与えられた文字列が有効な時間形式(HH:mm)かどうかを判定する
 *
 * 有効な時間形式:
 * - HH:mm (29時間表記)
 *
 * @param timeStr 時間文字列
 * @returns [boolean, number, number] - 有効な時間形式の場合は[true, 時間, 分]、それ以外は[false, 0, 0]
 */
export function getTimeValues(timeStr: string | null): [boolean, number, number] {
  if (timeStr === null) return [false, 0, 0];

  // 時間の正規表現パターン (29時間表記のHH:mmのみ)
  const timePattern = /^([01][0-9]|2[0-8]):([0-5][0-9])$/;
  const match = timePattern.exec(timeStr);

  if (match) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    console.log(`hours = ${hours}, minutes = ${minutes}`);
    return [true, hours, minutes];
  } else {
    return [false, 0, 0];
  }
}

/**
 * 与えられた文字列が有効な時間形式(HH:mm)かどうかを判定する
 *
 * 有効な時間形式:
 * - HH:mm (29時間表記)
 *
 * @param timeStr 時間文字列
 * @returns boolean - 有効な時間形式の場合はtrue、それ以外はfalse
 */
export function isValidTimeWithoutColon(timeStr: string | null): boolean {
  if (timeStr === null) return false;
  if (timeStr.length < 3) {
    return false;
  }

  return getTimeValues(`${timeStr.slice(0, 2)}:${timeStr.slice(2)}`)[0];
}

export function parseHHMMToDate(HHMMStr: string | null, baseDate: Date): [boolean, Date] {
  if (HHMMStr === null) return [false, new Date()];
  if (HHMMStr.length < 3) {
    return [false, new Date()];
  }
  const [isvalid, hours, mins] = getTimeValues(`${HHMMStr.slice(0, 2)}:${HHMMStr.slice(2)}`);
  if (isvalid) {
    const date = new Date(baseDate);
    date.setHours(hours);
    date.setMinutes(mins);
    return [true, date];
  } else {
    return [false, new Date()];
  }
}

/**
 * 与えられた文字列が有効な時間形式(HH:mm)かどうかを判定する
 *
 * 有効な時間形式:
 * - HH:mm (29時間表記)
 *
 * @param timeStr 時間文字列
 * @returns boolean - 有効な時間形式の場合はtrue、それ以外はfalse
 */
export function isValidTime(timeStr: string | null): boolean {
  return getTimeValues(timeStr)[0];
}

export function formatHHMM(date: Date, base: Date): string {
  const diffday = date.getDate() - base.getDate();
  const hours = date.getHours() + diffday * 24;

  return `${String(hours).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}`;
}

export const timeDisplayFormat = (time: number): string => {
  const hours = Math.floor(time);
  const minutes = Math.round((time - hours) * 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export function calculateShiftTime(startTime: Date, endTime: Date): number {
  return (endTime.getTime() - startTime.getTime()) / MILLISECONDS_PER_HOUR; // ミリ秒単位で結果を計算するので、この格差を時間に換算するため
}

export function calculateWorkingHour(startTime: Date, endTime: Date): number {
  let shiftTime = calculateShiftTime(startTime, endTime);
  // 休憩時間計算式
  if (shiftTime >= BREAK_TIME_NORMAL_HOUR) {
    shiftTime -= BREAK_TIME_NORMAL_HOUR;
  }
  return shiftTime;
}

export function calculateBreakTimeOfDay(startTime: Date, endTime: Date): number {
  if (endTime.getTime() - startTime.getTime() >= MILLISECONDS_PER_HOUR) {
    return BREAK_TIME_NORMAL_HOUR;
  } else {
    return BREAK_TIME_NO_TIME_HOUR;
  }
}

/**
 * 営業日かどうかを判定する
 * @param date 対象日付
 * @returns 営業日の場合true
 */
export const isWorkday = (date: Date): boolean => {
  return !isDayOff(date);
};

/**
 * 指定された日付が休日かどうかを判定します。
 * 日曜日(0)と土曜日(6)も休日として扱われます。
 * @param date 判定する日付
 * @returns 休日の場合は `true`、それ以外の場合は `false`
 */
export function isDayOff(date: Date): boolean {
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  const isHoliday = HOLIDAYS.some((holiday) => holiday.date.toDateString() === date.toDateString());
  return isWeekend || isHoliday;
}

/**
 * 指定された日付が祝日かどうかをチェックします。
 * @param date - チェックする日付。
 * @returns 日付が祝日の場合は `true`、そうでない場合は `false` を返します。
 */
export function isHoliday(date: Date): boolean {
  return HOLIDAYS.some(
    (holiday) =>
      holiday.date.getDate() === date.getDate() &&
      holiday.date.getMonth() === date.getMonth() &&
      holiday.date.getFullYear() === date.getFullYear(),
  );
}

export function FormatDate(date: Date): string {
  return `${String(date.getDate()).padStart(2, '0')}(${getDayOfWeek(date)})`;
}

/**
 * 対象日の曜日を取得
 * @param date
 * @returns 対象日の曜日
 */
export const getDayOfWeek = (date: Date): string => {
  const day = date.getDay();
  const weekItems = ['日', '月', '火', '水', '木', '金', '土'];
  return weekItems[day];
};

export const getLeaveFromString = (leaveString: string): LEAVE | undefined => {
  const leaveValue = Object.entries(LEAVE).find(([, value]) => value === leaveString)?.[0];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return leaveValue ? (LEAVE as any)[leaveValue] : undefined;
};

/**
 * 編集できないかを判定する
 * true: 編集できない
 * false: 編集できる 今月、今月の第一火曜日を過ぎていない場合先月のデータは編集できる
 * @param date 対象日
 * @param isManagerPage 管理者ページかどうか
 * @returns 対象日
 */
export const checkDisabledDate = (targetDate: Date, isManagerPage = false): boolean => {
  // 管理者ページの場合は常に編集可能
  if (isManagerPage) {
    return false;
  }

  const today = new Date();
  const lastWednesdayBeforeTenth = getLastWednesdayBeforeTenth(
    today.getFullYear(),
    today.getMonth(),
  );

  // 今日が10日以下で一番遅い水曜日 23:59:59.999を過ぎたかどうかを確認
  const pastFirstTuesday = today > lastWednesdayBeforeTenth;

  // 年と月を抽出
  const targetYear = targetDate.getFullYear();
  const targetMonth = targetDate.getMonth();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  // 今日の月とターゲットの日付の月が同じ場合
  if (targetYear === currentYear && targetMonth === currentMonth) {
    // 同じ月ならば 編集可能(false) を返す
    return false;
  } else if (targetYear === currentYear && targetMonth === currentMonth - 1) {
    // 今日の前の月がターゲットの日付の月の場合
    // 今月の第一火曜日を過ぎている場合は 編集不可(true） を返す
    // 過ぎてない場合は 編集可能(false） を返す
    return pastFirstTuesday ? true : false;
  } else {
    // 違う月なら  編集不可(true) を返す
    return true;
  }
};

/**
 * 10日以下で一番遅い水曜日 23:59:59.999を取得する関数。
 *
 * @param year - 年
 * @param month - 月 (0 - 11, 0が1月を指します)
 * @returns Dateオブジェクト (10日以下で最も遅い水曜日 23:59:59.999の日付)
 */
export const getLastWednesdayBeforeTenth = (year: number, month: number): Date => {
  const targetDate = new Date(year, month, 9, 23, 59, 59, 999);

  // 一番遅い水曜日を探す
  while (targetDate.getDay() !== 3) {
    targetDate.setDate(targetDate.getDate() - 1);
  }

  return targetDate;
};
